import { Link } from 'gatsby';
import * as React from 'react';

import { BackgroundTexture, Layout, SEO } from '../components';

function NotFoundPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <NotFound />
    </Layout>
  );
}

function NotFound(): React.ReactElement {
  return (
    <div className="relative px-6 py-12 mt-12 mb-24 text-center">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative pt-8">
        <h2 className="flex justify-center text-5xl font-semibold tracking-wide uppercase text-blue-dark heading-accent">
          404: Page Not Found
        </h2>
        <h3 className="mt-4 text-xl font-semibold tracking-wide uppercase text-blue-dark">
          Sorry, the page you were looking for could not be found
        </h3>
        <Link
          to="/"
          className="inline-flex items-center justify-center mt-4 button"
        >
          Return Home
        </Link>
      </div>
    </div>
  );
}

export { NotFoundPage as default };
